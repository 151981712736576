import React, { useContext, useState, useEffect, useMemo } from 'react';
import SignalRContext from '../../../signalr/context';
import { RoomEntryState } from '../../../enums/roomEntryState';
import { Chip, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { StandardTable } from '../../shared/table';
import { FullSite } from '../../../dtos/site';
import { hubConnection } from '../../../signalr/provider';
import { OrgSiteContext } from '../../orgSiteContext';
import { AuthContext } from '../../../auth/authContext';
import { CloudDone, CloudOff } from '@material-ui/icons';
import { navLinkUrl } from '../../../utils';

const RoomStatusChipColor = (status: RoomEntryState) => {
    switch (status) {
        case RoomEntryState.EnteringRoom:
        case RoomEntryState.EnteringRoomDoorOpen:
        case RoomEntryState.InRoom:
        case RoomEntryState.ExitingRoom:
            return 'primary';
        case RoomEntryState.Unauthorized:
        case RoomEntryState.BoxLeftOpen:
            return 'secondary';
        case RoomEntryState.Idle:
        default:
            return 'default';
    }
};

const RoomStatusChipText = (status: RoomEntryState, customerName: string) => {
    let customerNameText =
        customerName !== undefined && customerName !== null && customerName !== ''
            ? customerName
            : 'unknown customer';

    switch (status) {
        case RoomEntryState.EnteringRoom:
            return `${customerNameText} entering room.`;
        case RoomEntryState.EnteringRoomDoorOpen:
            return `${customerNameText} entering room, door is open.`;
        case RoomEntryState.InRoom:
            return `Room occupied by ${customerNameText}`;
        case RoomEntryState.ExitingRoom:
            return `${customerNameText} exiting room.`;
        case RoomEntryState.Unauthorized:
            return `Unauthorized activity`;
        case RoomEntryState.BoxLeftOpen:
            return 'Box Left Open';
        case RoomEntryState.Idle:
        default:
            return 'Idle';
    }
};

export const HomePage = ({ sites, dealerId }: { sites: Array<FullSite>; dealerId?: number | null }) => {
    const [alarmState, setAlarmState] = useState<Map<number, boolean>>(new Map());
    const { roomState, serviceStatus } = useContext(SignalRContext);
    const assignedOrgAndSite = useContext(AuthContext);
    const orgSiteContext = useContext(OrgSiteContext);
    const orgId = useMemo(() => orgSiteContext.orgId || assignedOrgAndSite.orgId, [
        assignedOrgAndSite.orgId,
        orgSiteContext.orgId,
    ]);
    const siteId = useMemo(() => orgSiteContext.siteId || assignedOrgAndSite.siteId, [
        orgSiteContext.siteId,
        assignedOrgAndSite.siteId,
    ]);

    useEffect(() => {
        hubConnection.on('alarmStateChanged', (siteId: number, active: boolean) => {
            setAlarmState(prev => {
                const newAlarmState = new Map(prev);
                newAlarmState.set(siteId, active);
                return newAlarmState;
            });
        });

        return () => {
            hubConnection.off('alarmStateChanged');
        };
    }, []);

    useEffect(() => {
        let alarmMap = new Map();
        sites.forEach((s) => alarmMap.set(s.id, s.activeAlarm));
        setAlarmState(alarmMap);
    }, [sites]);

    return (
        <>
            <StandardTable style={{ width: '60%' }}>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th style={{ width: '60%' }}>Site Name</th>
                        <th>Version</th>
                        <th>Status</th>
                        <th>Room</th>
                        <th>Alarms</th>
                    </tr>
                </thead>
                <tbody>
                    {sites.map((site) => {
                        let siteRoomState = roomState.get(site.id);
                        let siteAlarmState = alarmState.get(site.id);
                        return (
                            <tr key={site.id}>
                                <td>{site.uniqueSiteId}</td>
                                <td>{site.name}</td>
                                <td>
                                    {site.acsVersion}
                                </td>
                                <td>{serviceStatus.get(site.id) ? <CloudDone /> : <CloudOff />}</td>
                                <td>
                                    {siteRoomState ? (
                                        <Chip
                                            color={RoomStatusChipColor(siteRoomState.status)}
                                            label={RoomStatusChipText(
                                                siteRoomState.status,
                                                siteRoomState.customerName
                                            )}
                                        />
                                    ) : (
                                        <Chip
                                            color={'default'}
                                            label={RoomEntryState[RoomEntryState.Idle]}
                                        />
                                    )}{' '}
                                </td>
                                <td>
                                    <Link
                                        component={RouterLink}
                                        to={`/dealers/${dealerId}/organizations/${site.organizationId}/sites/${site.id}/alarms`}
                                    >
                                        {siteAlarmState ? (
                                            <Chip
                                                variant="outlined"
                                                color={'secondary'}
                                                label={'Active Alarm'}
                                            />
                                        ) : (
                                            <Chip
                                                variant="outlined"
                                                color={'primary'}
                                                label={'No alarms'}
                                            />
                                        )}
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </StandardTable>
            <div>
                <Link
                    component={RouterLink}
                    to={navLinkUrl("reports", dealerId, orgId, siteId)}
                >
                    Latest Events
                </Link>
            </div>
        </>
    );
};
