import React, { useContext, useMemo } from 'react';
import { OrgSelect } from '../../shared/OrgSelect';
import { HomePage } from './page';
import { PageHeader } from '../../PageHeader';
import { CloudDone, CloudOff } from '@material-ui/icons';
import SignalRContext from '../../../signalr/context';
import { AuthContext } from '../../../auth/authContext';
import { HelpVideo } from '../../../enums/helpVideo';
import { useOrgSiteContext } from '../../../hooks/useOrgSiteContext';
import { UserLevel } from '../../../enums/userLevel';
import { useFullSitesLoader } from '../../../hooks/useFullSitesLoader';

export const HomePageContainer = () => {
    const { hubConnected } = useContext(SignalRContext);
    const { roles, isGlobalAdmin, isDealerAdmin } = useContext(AuthContext);

    const deassocciateSiteFromContext =
        roles.indexOf(UserLevel.SiteAdmin) !== -1 ||
        roles.indexOf(UserLevel.SiteEmployee) !== -1 ||
        roles.indexOf(UserLevel.OrgAdmin) !== -1;

    const assignedOrgAndSite = useContext(AuthContext);
    const {
        dealerId: contextDealerId,
        orgId: contextOrgId,
        siteId: contextSiteId,
        siteChangeCallback,
    } = useOrgSiteContext('home');

    const dealerId = useMemo(
        () => contextDealerId || assignedOrgAndSite.dealerId,
        [assignedOrgAndSite.dealerId, contextDealerId]
    );
    const orgId = useMemo(
        () => contextOrgId || assignedOrgAndSite.orgId,
        [assignedOrgAndSite.orgId, contextOrgId]
    );
    const siteId = useMemo(
        () => contextSiteId || assignedOrgAndSite.siteId,
        [contextSiteId, assignedOrgAndSite.siteId]
    );

    const { sites } = useFullSitesLoader({ dealerId, siteId, orgId })

    return (
        <>
            <PageHeader helpVideoNum={HelpVideo.Login}>Home Page</PageHeader>
            <div style={{ display: 'flex' }}>
                <div>Server Status:</div>
                <div style={{ marginLeft: '10px' }}>
                    {hubConnected ? <CloudDone /> : <CloudOff />}
                </div>
            </div>

            <OrgSelect
                dealerSelectIsEnabled={isGlobalAdmin}
                orgSelectIsEnabled={isGlobalAdmin || isDealerAdmin}
                selectedDealerId={dealerId || null}
                selectedOrgId={orgId || null}
                selectedSiteId={siteId || null}
                onChange={(val: {
                    dealerId?: number | null;
                    orgId?: number | null;
                    siteId?: number | null;
                }) => siteChangeCallback(val.dealerId, val.orgId, val.siteId)}
                bypassSiteContext={deassocciateSiteFromContext}
            />

            <HomePage sites={sites} dealerId={dealerId} />
        </>
    );
};
