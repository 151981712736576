import React from "react";

import { Copyright } from "../Copyright";
import { TopBar } from "../TopBar";

const mainContentStyle: React.CSSProperties = {
    padding: "0 50px 0 50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    minHeight: "100vh",
    flexGrow: 1,
};

export const PageBody = ({ children }: { children?: any }) => (
    <main style={mainContentStyle}>
        <TopBar />
        <div>
            {children}
        </div>
        <div style={{ flexGrow: 1 }} />
        <div style={{ alignSelf: "center" }}>
            <Copyright />
        </div>
    </main>
);
