import { EnrollmentAcceptedData } from '../dtos/customer';
import { ManualOverrideAcceptedData } from '../dtos/manualOverride';
import { RoomEntryState } from '../enums/roomEntryState';

export interface SignalRState {
    hubConnected: boolean;
    serviceStatus: Map<number, boolean>;
    roomState: Map<number, { status: RoomEntryState; customerName: string }>;
    sessionCustomerName: string;
    sensorResetCompleted: number | undefined;
    systemRestartTriggered: number | undefined;
    enrollmentAcceptedData: EnrollmentAcceptedData | undefined;
    manualOverrideAcceptedData: ManualOverrideAcceptedData | undefined;
}

export const initialState: SignalRState = {
    hubConnected: false,
    serviceStatus: new Map(),
    roomState: new Map(),
    sessionCustomerName: '',
    sensorResetCompleted: undefined,
    systemRestartTriggered: undefined,
    enrollmentAcceptedData: undefined,
    manualOverrideAcceptedData: undefined,
};
