export enum AcsEventName {
    PanelStatusChanged = 0,
    SensorStatusChanged = 10,
    BoxStatusChanged = 20,
    RoomOccupiedStatusChanged = 30,
    DoorStatusChanged = 40,
    SessionStarted = 50,
    EnteringRoomDoorOpened = 51,
    UserInRoom = 52,
    UserExitingRoom = 53,
    SessionEnded = 54,
    AlarmTurnedOff = 60,
}

export const AcsEventNameDisplay = new Map([
    [AcsEventName.PanelStatusChanged, 'Panel Status Changed'],
    [AcsEventName.SensorStatusChanged, 'Sensor Status Changed'],
    [AcsEventName.BoxStatusChanged, 'Box Status Changed'],
    [AcsEventName.RoomOccupiedStatusChanged, 'Room Occupied Status Changed'],
    [AcsEventName.DoorStatusChanged, 'Door Status Changed'],
    [AcsEventName.SessionStarted, 'Room Session Started'],
    [AcsEventName.EnteringRoomDoorOpened, 'Entering Room'],
    [AcsEventName.UserInRoom, 'User in Room'],
    [AcsEventName.UserExitingRoom, 'User Exiting Room'],
    [AcsEventName.SessionEnded, 'Room Session Ended'],
    [AcsEventName.AlarmTurnedOff, 'Alarm Turned Off'],
]);
